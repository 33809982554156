/* App.css */
body {
  font-family: Arial, sans-serif;
  background-color: #122535;
  color: #fff;
  margin: 0;
  padding: 0;
}

#logo-div {
  margin-top: 100px;
  text-align: center;
  animation: fadeIn 4s;
}

#logo-img {
  max-width: 100%;
  vertical-align: middle;
}

#arc-img {
  max-width: 100%;
}

#logo-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#button-div {
  opacity: 0;
  position: fixed;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  animation: smooth-appear 3s ease forwards;
}

@keyframes smooth-appear {
  to {
    bottom: 20%;
    opacity: 1;
  }
}

df-messenger {
  z-index: 999;
  position: fixed;
  --df-messenger-font-color: #000;
  --df-messenger-font-family: Google Sans;
  --df-messenger-chat-background: #f3f6fc;
  --df-messenger-message-user-background: #d3e3fd;
  --df-messenger-message-bot-background: #fff;
  bottom: 0;
  right: 0;
  top: 50px;
  width: 400px;
}

#offcanvasRight {
  background-color: #122535;
}

.accordion-button {
  font-size: 0.87rem;
}

#offcanvas-close-button-faq,
#offcanvas-close-button {
  background-color: #fff;
  margin: 0.5rem;
}

#offcanvas-close-button-arc {
  margin: 0.5rem;
}

.app-title {
  font-size: 2.5rem;
  color: #0d6efd;
}

.nav-link {
  color: #0d6efd;
  cursor: pointer;
}

.nav-link.active {
  font-weight: bold;
  color: #0a58ca;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

.search-results {
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background-color: #fff;
}

.chat-box {
  background-color: #e9ecef;
  height: 200px;
  overflow-y: auto;
}

footer {
  /* background-color: #0d6efd; */
  font-size: 0.87rem;
  position: fixed;
  color: #ccc;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  opacity: 0;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  animation: smooth-appear-footer 3s ease forwards;
}

footer > p {
  margin: 0;
}

@keyframes smooth-appear-footer {
  to {
    bottom: 25px;
    opacity: 1;
  }
}
